import styled from 'styled-components';
import React from 'react'
import { Link } from "gatsby"

const Bartop = styled.div`
  
  font-size: 20px;
  font-weight: 600;
  a {
    font-size: 24px;
    padding: 10px 0px 10px 20px;

    &:hover {
      
      color: #ffe701;
    }
   
  }
  
`;

const BarListTop = styled.div`
  width: 100%;
  p {
    padding: 5px 20px 10px 3px;
    opacity: 40%;
    background:blue;
    
  }

  &:hover {
     
    color: #ffe701;
   
    
  }



`;

const BarPad = styled.div`
  padding: 0px;


  
`;



export default function NavMenu() {
  return (
    <nav>
      <BarPad>
      <Bartop> What we do</Bartop>

      <Link to="/Branding/"><BarListTop>Branding, Company Profile and Startup</BarListTop></Link>
        <Link to="/Photography/"><BarListTop>Photography and Video Production</BarListTop></Link>
        <Link to="/threeD/"><BarListTop>3D Modeling and Animation</BarListTop></Link>
        <Link to="/UiUx/"><BarListTop>UI and Apps</BarListTop></Link>
        <Link to="/Website/"><BarListTop>Website Development</BarListTop></Link>
        <Link to="/SocialContent/"><BarListTop>Social Media Content</BarListTop></Link>
        <Link to="/GraphicDesign/"><BarListTop>Graphic Design</BarListTop></Link>
        <Link to="/MarketingSEO/"><BarListTop>Marketing Strategy, Email and SEO</BarListTop></Link>
         
        </BarPad>

        
   
    </nav>
  );
}


