import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import Nav from '../components/Nav/Nav';
import Outout from './Out/Outout';
import Outout2 from './Out/Outout2';






export default function Header() {
  return (
    <header>
      <HeaderTop>
        <HeaderGroup>
          <div className="bar">
            <div className="barLogo">
              <Link to="/">
              <img src="/images/logos/logoStuxio.svg" width="60" />
              </Link>
            </div>
          </div>
          
          <Nav />
         
          <Account>
          <Outout2 />
            <MenuWrapper>
            <Outout />
            </MenuWrapper>
          </Account>
        </HeaderGroup>
      </HeaderTop>
      
 
     
     
    </header>
  );
}

const HeaderTop = styled.div`
  background: #ffffff;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: #f5f5f7;
  height: auto;

  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 1;
  
`;

const HeaderGroup = styled.div`
  margin: 0 auto;
  padding: 0px 10px;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  align-items: center;

  @media (max-width: 600px) {
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    
  }


  h2{

font-size: 30px;
  }
`;

const Account = styled.div`
  color: black;
  width: 160px;

  text-align: right;
  align-items: center;
  justify-items: right;
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  

  @media (max-width: 600px) {
    padding-right: 10px;
    justify-items: right;
  }
  



 
`;

const MenuWrapper = styled.div`
  cursor: pointer;
  width: 40px;
  float: right;
  justify-items: right;

  p {
    font-size: 14px;
  }
`;
