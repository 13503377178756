import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from './useOutsideClick';
import Navtop from '../Nav/Navtop';

export default function Outout() {
  const [show, setSate] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  return (
    <Ouside>
      <OusideIn onClick={() => setSate(!show)}>
        <h1>
          <i className="bi bi-three-dots" />
        </h1>
      </OusideIn>
      {show && (
        <div ref={ref} style={st_mdl}>
        
          <Navtop />
         
        </div>
      )}
    </Ouside>
  );
}

const Ouside = styled.div`
  margin: 0 auto;
`;

const st_mdl = {
  position: 'absolute',
  color: 'black',
  background: '#ffffff',
  borderRadius: '14px',
  padding: '20px',
  border: 'solid',
  borderWidth: '1px',
  borderColor: '#f5f5f7',

  width: 200,
  height: 400,
  top: '100px',
  right: '20px',
  marginLeft: 150,
  boxShadow: '30px 20px 40px rgba(0.35, 0.35, 0.35, 0.35)',
  
};






const OusideIn = styled.div`
  color: black;
  font-size: 12px;
  font-weight: 600;

 

  h1 {
    font-size: 30px;

    cursor: pointer;
  }

  h1 {
    cursor: pointer;
    color: black;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  h1:hover {
    color: #ffe701;
  }

  h1:hover:after {
    opacity: 1;
    right: 10px;
  }
`;
