import styled from 'styled-components';
import React from 'react'
import { Link } from "gatsby"

const Barone = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 40%;
`;

const Bartu = styled.div`
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 600;
`;

export default function NavThree() {
  return (
    <nav>
      <Bartu>
        <p> Shop</p>
      </Bartu>

      <Barone>
        <Link to="/">Marketplace</Link>
      </Barone>
      <Barone>
        <Link to="/">Creative Store</Link>
      </Barone>
      <Barone>
        <Link to="/">Book</Link>
      </Barone>
      <Barone>
        <Link to="/">Tutorial</Link>
      </Barone>
    </nav>
  );
}
