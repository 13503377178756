import React from 'react'
import { Link } from "gatsby"


import styled from 'styled-components';




const BarNav = styled.div`
  padding: 20px;
  margin: 20 auto;

  @media (max-width: 800px) {
    display: none;
  }

  a {
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: black;
  }

  a {
    cursor: pointer;
    color: black;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  a:after {
    content: '»';
    position: absolute;
    opacity: 0;
    right: 0px;
    transition: 0.5s;
    color: #ffe701;
  }

  a:hover {
    padding-right: 24px;
    color: black;
    opacity: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #ffe701;
  }

  a:hover:after {
    opacity: 1;
    right: 10px;
    color: #ffe701;
  }
`;

export default function Nav() {
  return (
    <nav>
      <BarNav>
        <Link to="/showreel/">Showreel</Link>
        <Link to="/stuxio/">Stuxio</Link>
        <Link to="/studio/">Studio</Link>
        <Link to="/contact/">Contact</Link>
       
       

      </BarNav>
      
    </nav>
  );
}
