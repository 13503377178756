import styled from 'styled-components';
import React from 'react'
import { Link } from "gatsby"

const Bartop = styled.div`
  
  font-size: 24px;
  font-weight: 600;
  a {
    font-size: 24px;
    padding: 10px 0px 10px 20px;

    &:hover {
      
      color: #ffe701;
    }

   
  }
  
`;

const BarListTop = styled.div`
  width: 100%;
  p {
    padding: 5px 20px 10px 3px;
    opacity: 40%;
    &:hover {
     
      color: #ffe701;
    }
  }
`;

const BarPad = styled.div`
  padding: 30px;
  
  
`;








export default function Navtop() {
  return (
    <nav>
      <BarPad>
      <Bartop>
        Stuxio Network
        
      </Bartop>

  
        <Link href="/showreel">
          <BarListTop>Showreel</BarListTop>
        </Link>
  

        <Link href="/stuxio">
          <BarListTop>Stuxio</BarListTop>
        </Link>


        <Link href="/studio">
          <BarListTop>Studio</BarListTop>
        </Link>

    
        <Link href="/contact">
          <BarListTop>Contact</BarListTop>
        </Link>
        <Bartop>
  
        <p>Follow us</p>
        <a href="https://www.instagram.com/stuxionetwork/"><i class="bi bi-instagram" ></i></a>
        <a href="https://www.facebook.com/stuxionetworkmalaysia/"><i class="bi bi-facebook"></i></a>
        <a href="https://www.linkedin.com/company/stuxionetwork"><i class="bi bi-linkedin"></i></a>
      </Bartop>
        </BarPad>

        
   
    </nav>
  );
}
