import styled from 'styled-components';
import React from 'react'
import { Link } from "gatsby"

const Barone = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 40%;
`;

const Bartu = styled.div`
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 600;
`;

export default function NavTwo() {
  return (
    <nav>
      <Bartu>
        <p> Follow us</p>
      </Bartu>

      <Barone>
      <a href="https://www.facebook.com/stuxionetworkmalaysia/">
          Facebook
        </a>
      </Barone>
      <Barone>
      <a href="https://www.instagram.com/stuxionetwork/">Instagram</a>
      </Barone>
      <Barone>
      <a href="https://www.linkedin.com/company/stuxionetwork">
          Linkedin
        </a>
      </Barone>
    </nav>
  );
}
