import styled from 'styled-components';
import React from 'react'
import NavTwo from '../Nav/Navtwo';
import NavThree from '../Nav/Navthree';




const FooterTop = styled.div`
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 1;
  bottom: 0;
  
  

`;

const FooterGroup = styled.div`
  max-width: 1700px;
  margin: 0 auto;
  color: black;
  text-align: left;
  font-size: 12px;
  padding: 50px 50px 20px 20px;
  
 
`;

const FooterIn = styled.div`
  margin-top: 50px;
  padding-top: 20px;
  border-top: solid;
  border-color: #24272c;
  border-width: 0.01em;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }




`;

const FooterUp = styled.div`
  margin-top: 50px;
  padding-top: 20px;

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  grid-gap: 25px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const FooterLogo = styled.div`
  padding-top: 0px;
  width: 100%;
  height: 100%;
  animation: HeroAnimation;
      animation-duration: 3s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
    line-height: 1.8;
   
  }
`;

export default function Footer() {
  return (
    <footer>
      <FooterTop>
        <FooterGroup>
          <FooterUp>
            <FooterLogo>
              <h4>STUXIO</h4>
              <p>
                We do what we do because we love doing it. We thrive on building
                lasting relationships while producing quality work for
                ambitious, open-minded clients who understand and value the
                creative process. We see our project as journey in Creative
                advertising.
              </p>
            </FooterLogo>
           <NavTwo />
           <NavThree />
            <div />
            <div />
          </FooterUp>

          <FooterIn>
            <p> © 2021 STUXIO All rights reserved.</p>
          </FooterIn>
        </FooterGroup>
      </FooterTop>
    </footer>
  );
}
