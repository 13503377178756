import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from './useOutsideClick';
import Navtop from '../Nav/Navtop';
import NavMenu from '../Nav/NavMenu';

export default function Outout() {
  const [show, setSate] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });

  return (
    <Ouside>
      <OusideIn onClick={() => setSate(!show)}>
        <h1>
          What we do
        </h1>
      </OusideIn>
      {show && (
        <div ref={ref} style={st_mdl}>
        
          <NavMenu />
         
        </div>
      )}
    </Ouside>
  );
}

const Ouside = styled.div`
  margin: 0 auto;
  
`;

const st_mdl = {
  position: 'absolute',
  color: 'black',
  background: '#ffffff',
  borderRadius: '14px',
  padding: '30px',
  border: 'solid',
  borderWidth: '1px',
  borderColor: '#f5f5f7',

  width: 'auto',
  height: 'auto',
  top: '100px',
  right: '20px',
  marginLeft: 150,
  boxShadow: '30px 20px 40px rgba(0.35, 0.35, 0.35, 0.35)',
  
};






const OusideIn = styled.div`
  color: black;
  font-size: 12px;
  font-weight: 400;
  justify-items: right;

 

  h1 {
    width: 100px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    border-color: #f5f5f7;
    padding: 10px;

  }

  h1 {
    cursor: pointer;
    color: black;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  h1:hover {
    color: white;
    background: black;
  }

  
`;
